import { useEffect, useRef, useState } from "react";
import { useAuth } from "../contexts/AuthCtx";
import { useUI } from "../contexts/UICtx";
import { getApiBase } from "../env";
import { Button, ButtonIcon, ButtonText } from "./Buttons";
import Divider from "./Divider";
import Icons from "./Icons";
import { SidebarTabs } from "./Sidebar";
import { useSk } from "../contexts/SkCtx";
import InitService from "../api/init";
import { CookiesArray, CookiesList } from "../constants/cookies";
import { useCookies } from "react-cookie";
import AssignSession from "assign-login";
import { SessionType } from "assign-login/dist/lib/requestSession";
import AuthService from "../api/auth";

var assignLoginSessionRef = null;
function HomeNavigationBar() {
  const uiCtx = useUI();
  const authCtx = useAuth();
  const skCtx = useSk();
  const [showBkMenu, setShowBkMenu] = useState(false);
  const bookmarkerPanelRef = useRef(null);
  const firstLoadRef = useRef(false);
  const [cookies, setCookie, removeCookie] = useCookies(CookiesArray);

  useEffect(() => {
    AssignSession.createSession({
      clientId: "6a9770de-f03d-4e2b-ba97-0881db1b62b2"
      // clientId: "e776d841-6a28-4728-aacf-8258ef4987a4"
    }, {
      sessionType: SessionType.PopupLogin
    }).then((r) => {
      assignLoginSessionRef = r;
    });
  }, []);
  

  const [mobileSearchActivated, setMobileSearchActivated] = useState(false);

  const handleOpenShorts = (e) => {
    // uiCtx.setSidemenuVisibility(true);
    // uiCtx.setSidemenuTab(SidebarTabs.Shorts);
    window.location.hash = "shorts";
  };

  const handleOpenSetting = (e) => {
    // uiCtx.setSidemenuVisibility(true);
    // uiCtx.setSidemenuTab(SidebarTabs.Settings);
    window.location.hash = "setting";
  };

  useEffect(() => {
    console.log("A :: ", authCtx.account)
  }, [authCtx.account]);

  useEffect(() => {
  }, []);

  const handleOnLoad = () => {
    if(authCtx.account && authCtx.account.signed) {
      if(bookmarkerPanelRef.current) {
        if(firstLoadRef.current) {
          return;
        }
        console.log("Sending message :: ")
        bookmarkerPanelRef.current.contentWindow.postMessage(JSON.stringify({
          token: cookies[CookiesList.AccessToken],
          refresh: true
        }), '*');
        firstLoadRef.current = true;
      }
    }
  }

  useEffect(() => {
    if(showBkMenu && authCtx.account && authCtx.account.signed) {
      if(bookmarkerPanelRef.current) {
        console.log("Sending message :: ")
        bookmarkerPanelRef.current.contentWindow.postMessage(JSON.stringify({
          token: cookies[CookiesList.AccessToken],
          refresh: false
        }), '*');
      }
    }
  }, [showBkMenu]);

  // const handleLoginClick = async (e) => {
  //   let resp = await InitService.getLoginToken({});
  //   if(resp.status === 200) {
  //     let j = await resp.data;
  //     let tokn = j.token;
  //     window.location.href = `https://bookmarker.me/login?apptoken=${tokn}`;
  //   }
  //   else
  //   {
  //     window.alert("Login failed")
  //   }
  // };

  const handleLoginClick = async (e) => {
    if(assignLoginSessionRef) {
      assignLoginSessionRef.launchFlow(async (code) => {
        /** send authcode to your backend, so you can get user info by authcode + clientSecret */
        console.log("Authcode :: ", code)
        if(code) {
          await AuthService.loginLoginSign({
            authcode: code
          }).then((r) => {
            authCtx.handleSetAuthCookie(r.data.token);
          })
        }
        else
        {
          window.alert("failed to login")
        }
      })
    }
  }

  const handleOnMoreClick = async (e) => {
    // setShowBkMenu(!showBkMenu)
    window.open("https://bookmarker.me", "_blank");
  }

  return (
    <>
      {skCtx.mobile_view ? "" : <div className="navigationBar">
        {mobileSearchActivated ?
          <div>

            <div className="navigation-searchbar"></div>

          </div>
          : <div>
            <div className="alignStart">
              {authCtx.account.signed ? (
                <></>
              ) : (
                <>
                  {/* <Button onClick={(e) => window.location.href = `${getApiBase()}auth/login`} text={"Login"} className={"popupButton fullRadius"} /> */}
                  {/* <span className="nv-seprator"></span> */}
                  {/* <Divider type="horiz" className="navigationBarItemDivider" /> */}
                  {/* <Button text={"Tutorials"} className={"popupButton fullRadius"} /> */}
                </>
              )}
            </div>
            {skCtx.setting['minimalistic'] ? <></> : <div>
              <div className="alignEnd mobileResponsiveView gap-8p">
                <span className="dummy"></span>
                {authCtx.initialized && authCtx.account && authCtx.account.signed && <>
                  <span className="new-button-appearence not-new"><ButtonIcon onClick={handleOnMoreClick}>
                  <Icons.AppIcon />
                </ButtonIcon></span>
                {/* <div className={("popup-box-bk")+(showBkMenu ? " show" : "")}>
                  <div className="inner">
                    <iframe onLoad={handleOnLoad} ref={bookmarkerPanelRef} src={`https://bookmarker.me/`}></iframe>
                  </div>
                </div> */}
                </>}
                {authCtx.initialized && authCtx.account && !authCtx.account.signed && <span className="new-button-appearence not-new" style={{display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center"}}><ButtonText onClick={handleLoginClick}>Login</ButtonText></span>}
                <span className="new-button-appearence not-new">
                  <ButtonIcon onClick={handleOpenSetting}>
                    <Icons.ControlIcon />
                  </ButtonIcon>
                  <Divider type="horiz" className="navigationBarItemDivider" />
                  <ButtonIcon onClick={handleOpenShorts}>
                    <Icons.MenuIcon />
                  </ButtonIcon>
                </span>
              </div>
            </div>}
          </div>}
      </div>}
    </>
  );
}

export default HomeNavigationBar;
