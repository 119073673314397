import { useEffect } from "react";
import { useLocation } from "react-router";
import { useAuth } from "../contexts/AuthCtx";

const AuthPage = () => {

    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const token = searchParams.get('token');

    const authCtx = useAuth();
    
    useEffect(() => {
        console.log("TXXXk :: ", token)
        if(token) {
            authCtx.handleSetAuthCookie(token);
        }
    }, [token]);
    
    return (
        <>
        </>
    );
}

export default AuthPage;