import { useEffect, useRef, useState } from "react";
import { useAuth } from "../../contexts/AuthCtx";
import { useUI } from "../../contexts/UICtx";
import { Button, ButtonIcon, ButtonText } from "../Buttons";
import CheckboxControl from "../Checkbox";
import Icons from "../Icons";
import Input from "../Input";
import { useSk } from "../../contexts/SkCtx";
import { TextArea } from "../TextArea";
import Editor from 'react-simple-code-editor';
import { highlight, languages } from 'prismjs/components/prism-core';
import 'prismjs/components/prism-markup'; // HTML syntax highlighting
import 'prismjs/themes/prism.css'; // Optional: you can use any Prism theme
import 'prismjs/plugins/line-numbers/prism-line-numbers.css'


export const AddShortMode = {
  Short: "short",
  Key: "key",
};

// export default function AddShortTab() {
//   const authCtx = useAuth();
//   const uiCtx = useUI();
//   const skCtx = useSk();

//   const titleRef = useRef(null);
//   const urlRef = useRef(null);
//   const keywordRef = useRef(null);

//   const [mode, setMode] = useState(AddShortMode.Short);

//   const [data, setData] = useState({});

//   const onCloseClick = (e) => {
//     uiCtx.setDialog(false);
//   };

//   const handleChangeTab = (tab) => (e) => {
//     setMode(tab);
//   };

//   useEffect(() => {
//     if (titleRef.current) {
//       titleRef.current.focus();
//     }
//   }, [mode]);

//   const onRemove = async (e) => {
//     onCloseClick(e);
//   };

//   const onDone = (e) => {
//     uiCtx.setDialog(false);
//   };

//   const save = async (data) => {
//     let item = {
//       ...(getItem() || {}),
//       ...(data || {}),
//     };
//     if (mode === AddShortMode.Short) {
//       if (item.short._id) {
//         skCtx.updateShort(item.short._id, item.short);
//       } else {
//         let id = await skCtx.createShort(item.short);
//         const val = {
//           short: {
//             ...(getItemShort() || {}),
//             _id: id,
//           },
//         };
//         uiCtx.setData({
//           item_short: {
//             ...(getItem() || {}),
//             ...val,
//           },
//         });
//       }
//     } else {
//       if (item.key._id) {
//         skCtx.updateKey(item.key._id, item.key);
//       } else {
//         let id = await skCtx.createKey(item.key);
//         const val = {
//           key: {
//             ...(getItemShort() || {}),
//             _id: id,
//           },
//         };
//         uiCtx.setData({
//           item_key: {
//             ...(getItem() || {}),
//             ...val,
//           },
//         });
//       }
//     }
//   };

//   const onTitleChange = (e) => {
//     setData({
//       ...data,
//       title: (e.target.value || "").trim()
//     });
//   };

//   const onTitleKeyUp = (e) => {
//     if (e.key === "Enter" && e.target.value.length > 0) {
//       addToKeywords(e.target.value.trim());
//       if (urlRef.current) {
//         urlRef.current.focus();
//       }
//       return;
//     }
//   };

//   const onURLChange = (e) => {
//     setData({
//       ...data,
//       url: (e.target.value || "").trim()
//     });
//   };

//   const onURLKeyUp = (e) => {
//     if (e.key === "Enter" && e.target.value.length > 0) {
//       if (keywordRef.current) {
//         keywordRef.current.focus();
//       }
//       return;
//     }
//   };

//   const addToKeywords = (kword) => {
//     if (getKeywords().includes(kword)) {
//       return false;
//     }
//     let k = mode === AddShortMode.Short ? "short" : "key";
//     const val = {
//       [k]: {
//         ...(getItemShort() || {}),
//         keywords: [...(getKeywords() || []), kword],
//       },
//     };
//     let k2 = mode === AddShortMode.Short ? "item_short" : "item_key";
//     uiCtx.setData({
//       [k2]: {
//         ...(getItem() || {}),
//         ...val,
//       },
//     });
//     save(val);
//     return true;
//   };

//   const onKeywordKeyUp = (e) => {
//   };

//   const onKeywordsChange = (e) => {
//     let k = e.target.value || "";
//     let kk = k.split(", ");
//     kk = kk.map((ks) => ks.trim());
//     setData({
//       ...data,
//       keywords: kk
//     });
//   }

//   useEffect(() => {
//     let k = "item_short";
//     let new_data = data;
//     if(!(uiCtx.data[k] && Object.keys(uiCtx.data[k]).length > 0)) {
//       k = "new_short_data";
//     }
//     else
//     {
//       new_data = {
//         ...uiCtx.data[k],
//         ...data
//       }
//     }
//     console.log("xxx n :: ", new_data)
//     if(new_data) {
//       uiCtx.setData({
//         [k]: new_data
//       });
//     }
//     else
//     {
//       uiCtx.setData({
//         [k]: null
//       });
//     }
//   }, [data])

//   const getShortItem = () => {
//     return uiCtx.data["item_short"] || null;
//   };

//   const getKeyItem = () => {
//     return uiCtx.data["item_key"] || null;
//   };

//   const getItem = () => {
//     if (mode === AddShortMode.Short) {
//       return getShortItem();
//     } else {
//       return getKeyItem();
//     }
//   };

//   const getItemShort = () => {
//     let item = getItem();
//     return item;
//   };

//   const getItemShortShort = () => {
//     let item_short = getItemShort();
//     if (mode === AddShortMode.Short) {
//       return item_short ? item_short.short : null;
//     } else {
//       return item_short ? item_short.key : null;
//     }
//   };

//   const getTitle = () => {
//     let short = getItemShort();
//     return short ? short.title || null : null;
//   };

//   const getURL = () => {
//     let item_short_short = getItemShortShort();
//     if(item_short_short) {
//       return item_short_short.url || item_short_short.target;
//     }
//     return null;
//   };

//   const getKeywords = () => {
//     let item_short = getItemShort();
//     return item_short ? item_short.keywords || [] : [];
//   };

//   const getKeywordsText = () => {
//     let j = getKeywords();
//     if(j) {
//       return j.join(", ")
//     }
//     return ""
//   }

//   useEffect(() => {
//     if (
//       (uiCtx.dialog_args &&
//         uiCtx.dialog_args.length > 0 &&
//         uiCtx.dialog_args[0] === AddShortMode.Short) ||
//       uiCtx.dialog_args[0] === AddShortMode.Key
//     ) {
//       setMode(uiCtx.dialog_args[0]);
//     }
//   }, [uiCtx.dialog_args]);

//   useEffect(() => {
//     if (titleRef.current) {
//       titleRef.current.value = getTitle();
//     }
//     if (urlRef.current) {
//       urlRef.current.value = getURL();
//     }
//   }, [mode]);

//   return (
//     <>
//       {mode && (
//         <>
//           <div className="spacer mb-12"></div>
//           <div className="dialog_col titlebar common-shorts-w">
//             <div>
//               <ButtonIcon onClick={onRemove}>
//                 <Icons.DeleteIcon color={Icons.IconColors.active} />
//               </ButtonIcon>
//             </div>
//             <div>
//             </div>
//             <div>
//               <ButtonIcon onClick={onCloseClick}>
//                 <Icons.CloseIcon color={Icons.IconColors.active} />
//               </ButtonIcon>
//             </div>
//           </div>
//           <div className="spacer h-12"></div>
//           <div className="dialog_col input-container f-start mb-6 input-container-title">
//             <div className="inner-col">
//               <span>Shortkey</span>
//             </div>
//           </div>
//           <div className="dialog_col input-container">
//             <div className="inner-col">
//               <Input
//                 ref={titleRef}
//                 defaultValue={getTitle() ? getTitle() : undefined}
//                 onChange={onTitleChange}
//                 onKeyUp={onTitleKeyUp}
//                 autoFocus={true}
//                 placeholder={"Title"}
//               />
//             </div>
//           </div>
//           <div className="spacer h-30"></div>
//           <div className="dialog_col input-container f-start mb-6 input-container-title">
//             <div className="inner-col">
//               <span>Keywords</span>
//             </div>
//           </div>
//           <div className="dialog_col input-container common-w">
//             <div className="inner-col">
//               <Input
//                 ref={keywordRef}
//                 onKeyUp={onKeywordKeyUp}
//                 onChange={onKeywordsChange}
//                 placeholder={"Separate keywords with comma."}
//                 defaultValue={getKeywordsText()}
//               />
//             </div>
//           </div>
//           <div className="spacer h-30"></div>
//           <div className="dialog_col input-container f-start mb-6 input-container-title">
//             <div className="inner-col">
//               <span>URL</span>
//             </div>
//           </div>
//           <div key={mode} className="dialog_col input-container common-w">
//             <div className="inner-col">
//               <Input
//                 ref={urlRef}
//                 key={mode}
//                 onKeyUp={onURLKeyUp}
//                 onChange={onURLChange}
//                 placeholder={"Insert @@@ in the URL where you want the search terms"}
//               />
//             </div>
//           </div>
//           <div className="spacer h-12"></div>
//           <div className="spacer h-30"></div>
//           <div className="spacer mb-12"></div>
//         </>
//       )}
//     </>
//   );
// }

export default function AddShortTab({data, setData, updateIdRef}) {
  const authCtx = useAuth();
  const uiCtx = useUI();
  const skCtx = useSk();

  const [selectedTab, setSelectedTab] = useState("url");

  const titleRef = useRef(null);
  const urlRef = useRef(null);
  const keywordRef = useRef(null);

  const saveRef = useRef(null);

  const idRef = useRef(null);
  const isInProgressRef = useRef(false);
  // const [data, setData] = useState({});

  const [htmlCode, setHtmlCode] = useState('');

  const [urlFocused, setURLFocused] = useState(false);

  const onCloseClick = (e) => {
    if (idRef.current) {
      if((data.title || "").length < 1 && (data.url || "").length < 1) {
        if (idRef.current) {
          authCtx.deleteItem(idRef.current);
        }
      }
    }
    uiCtx.setDialog(false);
  };

  useEffect(() => {
    setData({
      ...data,
      url: `<html>:${htmlCode.trim()}`
    });
  }, [htmlCode]);

  const mergeNte = () => {
    let d = data.url;
    if((d || "").trim().startsWith("<note>:")) {
      return d;
    }
    else
    {
      return "<note>:";
    }
  }

  useEffect(() => {
    if(selectedTab === "note") {
      setData({
        ...data,
        url: mergeNte()
      })
    }
  }, [selectedTab]);

  const onRemove = async (e) => {
    if (idRef.current) {
      authCtx.deleteItem(idRef.current);
    }
    onCloseClick(e);
  };

  const onDone = (e) => {
    uiCtx.setDialog(false);
  };

  const onTitleChange = (e) => {
    // let shortkeys = [...(data.keywords || [])];

    // let k = shortkeys;
    // if (shortkeys.length < 1) {
    //   keywordRef.current.value = (e.target.value || "").trim();
    //   k = [(e.target.value || "").trim()];
    // } else if (shortkeys.length === 1) {
    //   if(((e.target.value || "").trim()).startsWith(shortkeys[0])) {
    //     keywordRef.current.value = (e.target.value || "").trim();
    //     k = [(e.target.value || "").trim()];
    //   }
    // }
    setData({
      ...data,
      title: (e.target.value || "").trim(),
      // keywords: k,
    });
  };

  const onTitleKeyUp = (e) => {
    if (e.key === "Enter" && e.target.value.length > 0) {
      // addToKeywords(e.target.value.trim());
      // if (urlRef.current) {
      //   urlRef.current.focus();
      // }
      if(keywordRef.current) {
        keywordRef.current.focus();
      }
      return;
    }
  };

  const addHttps = (t) => {
    if(t.startsWith("http://") || t.startsWith("https://")) {
      return t;
    }
    else
    {
      return `https://${t}`;
    }
  }

  const onURLChange = (e) => {
    let u = ((e.target.value || "").trim()).toLowerCase();
    e.target.value = u;
    setData({
      ...data,
      url: addHttps(u),
    });
  };

  const onURLKeyUp = (e) => {
    if (e.key === "Enter" && e.target.value.length > 0) {
      if (keywordRef.current) {
        keywordRef.current.focus();
      }
      return;
    }
  };


  const onNoteChange = (e) => {
    let u = ((e.target.value || "").trim());
    // e.target.value = u;
    setData({
      ...data,
      url: `<note>:${u}`,
    });
  };

  const onNoteKeyUp = (e) => {
    if (e.key === "Enter" && e.target.value.length > 0) {
      if (keywordRef.current) {
        keywordRef.current.focus();
      }
      return;
    }
  };


  const onPromptChange = (e) => {
    let u = ((e.target.value || "").trim());
    // e.target.value = u;
    setData({
      ...data,
      url: u,
    });
  };

  const onPromptKeyUp = (e) => {
    if (e.key === "Enter" && e.target.value.length > 0) {
      if (keywordRef.current) {
        keywordRef.current.focus();
      }
      return;
    }
  };

  const onHtmlChange = (u) => {
    // e.target.value = u;
    setData({
      ...data,
      url: `<html>:${u.trim()}`,
    });
  };

  const onHtmlKeyUp = (e) => {
    // if (e.key === "Enter" && u.trim().length > 0) {
    //   if (keywordRef.current) {
    //     keywordRef.current.focus();
    //   }
    //   return;
    // }
  };

  const onKeywordKeyUp = (e) => {

    const value = keywordRef.current.value;

    if(e.key === ' ') {
      if(keywordRef.current.value[keywordRef.current.value.length-2] === ' ') {
        keywordRef.current.value = keywordRef.current.value.trim()+" ";
      }
      else
      {
        keywordRef.current.value = keywordRef.current.value.trim() + ", "
      }
    }
    else if(e.key === ',') {
      let v = keywordRef.current.value;
      v.slice(0, keywordRef.current.length-2);
      // console.log("XX U :: ", keywordRef.current.value, ", V :: ", v);
      if(keywordRef.current.value[keywordRef.current.value.length-2] === ' ') {
        let tags = value.split(',').map(tag => tag.trim());
        tags = tags.filter(tag => tag.length > 0);
        keywordRef.current.value = tags.join(', ');
      }
      else if(keywordRef.current.value[keywordRef.current.value.length-2] === ',') {
        // console.log("XXX")
        let tags = value.split(',').map(tag => tag.trim());
        tags = tags.filter(tag => tag.length > 0);
        keywordRef.current.value = tags.join(', ');
      }
      else
      {
        keywordRef.current.value = v + " "
      }
    }
    else
    {
      let tags = value.split(',').map(tag => tag.trim());
      tags = tags.filter(tag => tag.length > 0);
      keywordRef.current.value = tags.join(', ');

    }
  };

  function parseKeys(keysString) {
    const keysArray = keysString.split(/\s*,\s*/);
    const flattenedArray = keysArray.reduce((acc, key) => {
        if (key.includes(" ")) {
            acc.push(...key.split(" "));
        } else {
            acc.push(key);
        }
        return acc;
    }, []);
    return flattenedArray.filter(Boolean);
}


  const onKeywordsChange = (e) => {
    let k = e.target.value || "";
    setData({
      ...data,
      keywords: parseKeys(k),
    });
  };

  useEffect(() => {
    console.log("current shortkey :: ", skCtx.current_shortkey);
    if (skCtx.current_shortkey) {
      idRef.current = skCtx.current_shortkey._id;
      updateIdRef(skCtx.current_shortkey._id);
      if(skCtx.current_shortkey.target) {
        if(skCtx.current_shortkey.target.startsWith("http://") || skCtx.current_shortkey.target.startsWith("https://")) {
          setSelectedTab("url");
        }
        else if(skCtx.current_shortkey.target.startsWith("<note>:")) {
          setSelectedTab("note");
        }
        else if(skCtx.current_shortkey.target.startsWith("<html>:")) {
          setSelectedTab("html");
          setHtmlCode((skCtx.current_shortkey.target || "").substring(7, (skCtx.current_shortkey.target || "").length));
        }
        else
        {
          setSelectedTab("ai");
        }
      }
      setData({
        ...skCtx.current_shortkey,
        keywords: skCtx.current_shortkey["shortkeys"] ||
          skCtx.current_shortkey["keywords"],
      });
    }
  }, [skCtx.current_shortkey]);

  // useEffect(() => {
  //   if(data && data.current_shortkey && data.current_shortkey.url) {
  //     let k = data.current_shortkey.url;
  //     if(k.startsWith("http://") || k.startsWith("https://")) {
  //       setSelectedTab("url");
  //     }
  //     else
  //     {
  //       selectedTab("ai");
  //     }
  //   }
  // }, [data]);

  const refreshSearch = () => {
    let status = skCtx.active_html.item || skCtx.active_note || skCtx.active_widget.key;
    // console.log("Refresh search status :: ", status, skCtx.finder.user_query);
    if(status) {

    }
    else
    {
      let text_query = skCtx.finder.user_query;
      skCtx.find(text_query);
    }
  }

  useEffect(() => {
    console.log("new shortkey :: ", data, Object.keys(data).length);
    if (data && Object.keys(data).length > 0) {
      console.log("CC");
      // if (!isInProgressRef.current) {
      if (saveRef.current) {
        clearTimeout(saveRef.current);
        saveRef.current = null;
      }
      saveRef.current = setTimeout(() => {
        if (!idRef.current) {
          if (!isInProgressRef.current) {
            isInProgressRef.current = true;
            authCtx.createItem(
              {
                title: data["title"],
                shortkeys: data["keywords"],
                target: data["url"],
              },
              (r) => {
                idRef.current = r._id;
                updateIdRef(r._id);
                isInProgressRef.current = false;
              }
            );
            refreshSearch();
          }
        } else {
          isInProgressRef.current = true;
          authCtx.updateItem(
            idRef.current,
            {
              title: data["title"],
              shortkeys: data["keywords"],
              target: data["url"],
            },
            (r) => {
              isInProgressRef.current = false;
            }
          );
          refreshSearch();
        }
      }, 200);
      // }
    }

    // if(titleRef.current) {
    //   titleRef.current.focus();
    // }
    
  }, [data]);

  useEffect(() => {
    if(titleRef.current) {
      titleRef.current.focus();
    }
  }, [uiCtx.sidemenu_active]);

  const getTitle = () => {
    if (skCtx.current_shortkey) {
      return skCtx.current_shortkey["title"];
    }
    return null;
  };

  const getURL = () => {
    if (skCtx.current_shortkey) {
      return skCtx.current_shortkey["target"];
    }
    return null;
  };

  const getPrompt = () => {
    if (skCtx.current_shortkey) {
      try {
        new URL(skCtx.current_shortkey["target"]);
      } catch (error) {
        return skCtx.current_shortkey["target"];
      }
      return null;
    }
    return null;
  };

  const getNote = () => {
    if(skCtx.current_shortkey) {
      if((skCtx.current_shortkey['target'] || "").startsWith("<note>:")) {
        return skCtx.current_shortkey['target'].substring(7, skCtx.current_shortkey['target'].length)
      }
    }
    return null;
  };

  const getHtml = () => {
    if(skCtx.current_shortkey) {
      if((skCtx.current_shortkey['target'] || "").startsWith("<html>:")) {
        return skCtx.current_shortkey['target'].substring(7, skCtx.current_shortkey['target'].length)
      }
    }
    return null;
  };

  const getKeywords = () => {
    if (skCtx.current_shortkey) {
      return skCtx.current_shortkey["shortkeys"];
    }
    return null;
  };

  const getKeywordsText = () => {
    let j = getKeywords();
    if (j) {
      return j.join(", ");
    }
    return "";
  };

  const handleMakeCopy = (e) => {
    // var tempTextArea = document.createElement("textarea");
    // tempTextArea.value = "@@@";
    // document.body.appendChild(tempTextArea);
    // tempTextArea.select();
    // document.execCommand("copy");
    // document.body.removeChild(tempTextArea);

    // if(urlRef.current) {
    //   urlRef.current.focus();
    // }
  }

  return (
    <>
      <>
        <div className="spacer mb-12"></div>
        <div className="dialog_col titlebar common-shorts-w"></div>
        {/* <div className="dialog_col titlebar common-shorts-w">
          
          <div>
            <ButtonIcon onClick={onCloseClick}>
              <Icons.CloseIcon color={Icons.IconColors.active} />
            </ButtonIcon>
          </div>
          <div></div>
          <div>
            <ButtonIcon onClick={onRemove}>
              <Icons.DeleteIcon color={Icons.IconColors.active} />
            </ButtonIcon>
          </div>
        </div> */}
        <div className="spacer h-12"></div>
        <div className="dialog_col input-container f-start mb-6 input-container-title">
          <div className="inner-col">
            <span>Shortkey</span>
          </div>
        </div>
        <div className="dialog_col input-container">
          <div className="inner-col">
            <Input
              ref={titleRef}
              defaultValue={getTitle() ? getTitle() : undefined}
              onChange={onTitleChange}
              onKeyUp={onTitleKeyUp}
              autoFocus={true}
              placeholder={"Title"}
            />
          </div>
        </div>
        <div className="spacer h-30"></div>
        <div className="dialog_col input-container f-start mb-6 input-container-title">
          <div className="inner-col">
            <span>Keywords</span>
          </div>
        </div>
        <div className="dialog_col input-container common-w">
          <div className="inner-col">
            <Input
              ref={keywordRef}
              onKeyUp={onKeywordKeyUp}
              onChange={onKeywordsChange}
              placeholder={"Separate keywords with comma."}
              defaultValue={getKeywordsText()}
            />
          </div>
        </div>
        <div className="spacer h-30"></div>
        {/* <div className="dialog_col input-container f-start mb-6 input-container-title">
          <div className="inner-col">
            <span onClick={handleMakeCopy}>URL</span>
          </div>
        </div> */}
        {/* <div className="dialog_col input-container common-w">
          <div className="inner-col">
            <Input
              ref={urlRef}
              onKeyUp={onURLKeyUp}
              onChange={onURLChange}
              defaultValue={getURL() ? getURL() : undefined}
              onFocus={(e) => setURLFocused(true)}
              onBlur={(e) => setURLFocused(false)}
              placeholder={"https://wikipedia.org/wiki/@@@"}
            />
            <div style={{ marginTop: 6, paddingLeft: 24 }}>
                <span style={{ fontSize: 14 }}>
                  Insert @@@ in the URL where you want the search terms
                </span>
              </div>
          </div>
        </div> */}
        {/* <div className="spacer h-12"></div> */}
        <div className="spacer h-12"></div>
        {/* <div className="spacer h-30"></div> */}
        <div className="skey-dialog-tabs">
          <div className="tab-container">
            <div onClick={() => setSelectedTab("url")} className={("tab")+(selectedTab === "url" ? " active" : "")}>URL</div>
            <div onClick={() => setSelectedTab("ai")} className={("tab")+(selectedTab === "ai" ? " active" : "")}>AI</div>
            {/* <div onClick={() => setSelectedTab("note")} className={("tab")+(selectedTab === "note" ? " active" : "")}>Note</div> */}
            <div onClick={() => setSelectedTab("html")} className={("tab")+(selectedTab === "html" ? " active" : "")}>HTML</div>
          </div>
        </div>
        <div className="spacer h-30"></div>
        {selectedTab === "url" ? <>
          <div className="dialog_col input-container f-start mb-6 input-container-title">
            <div className="inner-col">
              <span onClick={handleMakeCopy}>URL</span>
            </div>
          </div>
          <div className="dialog_col input-container common-w">
            <div className="inner-col">
              <Input
                ref={urlRef}
                onKeyUp={onURLKeyUp}
                onChange={onURLChange}
                defaultValue={getURL() ? getURL() : undefined}
                onFocus={(e) => setURLFocused(true)}
                onBlur={(e) => setURLFocused(false)}
                placeholder={"https://wikipedia.org/wiki/@@@"}
              />
              <div style={{ marginTop: 6, paddingLeft: 24 }}>
                  <span style={{ fontSize: 14 }}>
                    Insert @@@ in the URL where you want the search terms
                  </span>
                </div>
            </div>
          </div>
        </> : selectedTab === "ai" ? <>
        <div className="dialog_col input-container f-start mb-6 input-container-title">
            <div className="inner-col">
              <span>Prompt</span>
            </div>
          </div>
          <div className="dialog_col input-container common-w">
            <div className="inner-col">
            <TextArea
                onKeyUp={onPromptKeyUp}
                onChange={onPromptChange}
                defaultValue={getPrompt() ? getPrompt() : undefined}
                onFocus={(e) => setURLFocused(true)}
                onBlur={(e) => setURLFocused(false)}
                placeholder={"Describe the task you would like the system to assist with"}
              />
            </div>
          </div>
        </> : selectedTab === "html" ? <>
        <div className="dialog_col input-container f-start mb-6 input-container-title">
            <div className="inner-col">
              <span>HTML</span>
            </div>
          </div>
          <div className="dialog_col input-container common-w">
            <div className="inner-col">
            {/* <TextArea
                onKeyUp={onHtmlKeyUp}
                onChange={onHtmlChange}
                defaultValue={getHtml() ? getHtml() : undefined}
                onFocus={(e) => setURLFocused(true)}
                onBlur={(e) => setURLFocused(false)}
                placeholder={"HTML Code or Embeds"}
              /> */}
                <Editor
                  value={htmlCode}
                  onValueChange={(code) => setHtmlCode(code)}
                  highlight={(code) => highlight(code, languages.markup, 'html')}
                  padding={10}
                  style={{
                    fontFamily: '"Fira Code", "Fira Mono", monospace',
                    fontSize: 12,
                    backgroundColor: '#2d2d2d', // Dark background
                    color: '#f8f8f2', // Light text color
                    minHeight: '200px',
                    border: 'none', // Dark border
                    borderRadius: '16px',
                    outline: 'none',
                    width: "100%",
                    height: 200,
                    overflow: "auto"
                  }}
                />
            </div>
          </div>
        </> : selectedTab === "note" ? <>
        <div className="dialog_col input-container f-start mb-6 input-container-title">
            <div className="inner-col">
              <span>Note</span>
            </div>
          </div>
          <div className="dialog_col input-container common-w">
            <div className="inner-col">
            <TextArea
                onKeyUp={onNoteKeyUp}
                onChange={onNoteChange}
                defaultValue={getNote() ? getNote() : undefined}
                onFocus={(e) => setURLFocused(true)}
                onBlur={(e) => setURLFocused(false)}
                placeholder={"Your Note"}
              />
            </div>
          </div>
        </> : <></>}
        <div className="spacer h-30"></div>
        <div className="footer-buttons">
          <Button onClick={onRemove} text={"Remove"}></Button>
          <ButtonText onClick={onCloseClick}>Done</ButtonText>
        </div>
        <div className="spacer h-30"></div>
      </>
    </>
  );
}
